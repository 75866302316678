export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'What We Look For',
    path: '/what-we-look-for',
  },
  {
    title: 'Why Us',
    path: '/why-us',
  },
  {
    title: 'Who We Are',
    path: '/who-we-are',
  },
  {
    title: 'Our Process',
    path: '/our-process',
  },
  // {
  //   title: 'Blog',
  //   path: '/blog',
  // },
  // {
  //   title: 'Contact',
  //   path: '/contact',
  // },
];
